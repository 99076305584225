.portfolio-button {
    width: 100%;
    max-width: 1400px;
    display: flex;
    align-items: flex-end;
    margin: auto;
  }


  
  .portfolio-button label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #555;
  }
  
  .portfolio-button input[type="checkbox"] {
    appearance: none;
    width: 50px;
    height: 25px;
    background-color: #cccccc;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
  }


  
  .portfolio-button input[type="checkbox"]:checked {
    background-color: #cccccc;
  }
  
  .portfolio-button input[type="checkbox"]::before {
    content: "";
    position: absolute;
    width: 23px;
    height: 23px;
    background-color: #010808e7;
    border-radius: 50%;
    top: 1px;
    left: 1px;
    transition: transform 0.3s ease;
  }
  
  .portfolio-button input[type="checkbox"]:checked::before {
    transform: translateX(25px);
    
  }
  
  .portfolio-chart {
    margin: 5px auto 0px auto;
    width: 95%;
    max-width: 1400px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
  
.portfolio-chart-background{
  background-color: rgb(22, 167, 131);
  padding: 50px 0px 0px 0px;
}

  .hover-data {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #444;
  }


    
  .hovered-container{
    background-color: #010808e7;
    border-radius: 15px 15px 0px 0px;
    padding: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
  }

  .hovered-left, .hovered-right{
    align-content: center;
  }

  .hovered-right{
    text-align: right;
  }

  .hovered-left{
    text-align: left;
  }