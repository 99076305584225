.top-info-name-container {
    background: linear-gradient(to right,  #beaaaa00, #00000000),
                url("https://www.gowiththegurus.de/images/gurupage/GoWithTheGurus_TopInfo1.webp")
                ;
    background-size: cover;
    background-position: left center; /* Bild bleibt zentriert und beginnt links */
    max-width: 1400px;
    width: 95%;
    padding: 20px 10px;
    margin: 20px auto 0px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.top-info-name-container-title{
    font-weight: 900;
    font-size: 24px;
    color: #ffffff;
}

.top-info-fundname-container-title{
    font-weight: 900;
    font-size: 20px;
    color: #ffffffb2;
}

.top-info-name-container-bottom-line {
    font-size: 16px;
    color: rgb(255, 255, 255);
    margin-top: 5px;
}

.line{
    width: 100%;
    height: 3px;
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    margin: 10px 0px;
}

.top-info-header-image-post{
    width: 100%;
    padding:100px 0px;
    object-fit: cover;
    background-size:150%;
    background-position:50% 50%; /* Standard: Bildmitte */
    background-color: rgb(0, 34, 18);
    background-image: url("https://www.gowiththegurus.de/images/gurupage/GoWithTheGurus_TopInfo2.webp");
    background-repeat: no-repeat;
    
}

@media (max-width: 768px) {
    .top-info-header-image-post{
        background-size:400%;
    }
}