.blog-post-header-image-DK{
    
    width: 100%;
    padding:100px 0px;
    object-fit: cover;
    background-size:100%;
    background-position: 50% 50%; /* Standard: Bildmitte */
    background-color: rgb(0, 34, 18);
    background-image: url("https://www.gowiththegurus.de/images/blog/Header_background-NYC.webp");
    background-repeat: no-repeat;
}

.blog-image-container {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  }
  
  .blog-image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* damit das Bild das Div schön füllt */
    border-radius: 5px;
  }

  /* Container für das Video */
.responsive-video {
    position: relative;
    width: 100%;
    max-width: 1400px; /* Optional: Maximalbreite für große Bildschirme */
    margin: 20px auto 0px auto; /* zentriert mit Abstand oben/unten */
    padding-bottom: 56.25%; /* 16:9 Verhältnis */
    height: 0;
    overflow: hidden;
    border-radius: 12px; /* Optional: Runde Ecken */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Optional: Leichter Schatten */
  }
  
  /* Eingebettetes Video */
  .responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  


@media (max-width: 768px) {

    .blog-post-header-image-DK{

        background-size:300%;
    }

}