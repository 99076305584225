footer {
  background-color: #010808e7;
  color: white;
  height: auto;
  padding: 20px 0;
}

.footer-content {
  max-width: 1400px;
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}


.footer-container{
  width: 95%;
  max-width: 1400px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.footer-section {
  max-width: 30%;
  flex: 1;
  text-align: justify; /* Zentrierter Text */
}

.footer-section h4 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #ffffff50;
  padding-bottom: 5px;
}

.footer-section p,
.footer-section a {
  font-size: 14px;
  color: #ffffffd3;
  text-decoration: none;
}

.footer-section a:hover {
  color: white;
  text-decoration: underline;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 5px 0;
}

.footer-section a {
  transition: color 0.3s ease;
}

.disclaimer{
  width: 95%;
  max-width: 1400px;
  font-size: 10px;
  opacity: 0.5;
  margin: auto;
  text-align: justify; /* Zentrierter Text */
}

.footer-container{
  width: 95%;
  max-width: 1400px;
  margin: 20px auto;
}

.social-container{
  display: flex;
  gap:10px;
}

.social-icon{
  opacity: 0.8;
  transition: 0.3s ease;
}

.social-icon:hover{
  opacity: 1;
  transition: 0.3s ease;
}

/* --- Mobile Styles --- */
@media (max-width: 1000px) {
  .footer-content {
    flex-direction: column; /* Inhalte untereinander stapeln */
    align-items: center; /* Zentrierte Inhalte */
    text-align: center; /* Zentrierter Text */
  }

  .footer-section {
    margin: 10px 0; /* Abstand zwischen den Abschnitten */
    min-width: 100%;
  }

  .footer-section h4 {
    font-size: 16px;
  }

  .footer-section p,
  .footer-section a {
    font-size: 13px; /* Kleinere Schriftgröße */
  }


}

