.landing-page-first-row-container{
  min-height: 800px;
  margin: auto;
}

.section-one{
  width: 100%;
  background-color: rgb(255, 255, 255);
  
}

.headline-one{
  color: rgb(255, 21, 21);
  width: 95%;
  max-width: 1400px;
  margin: auto auto 10px auto;
  font-size: 40px;
  font-weight: 900;
  padding-top: 50px;
}


.headline-just-in{
  color: rgb(255, 255, 255);
  width: 95%;
  max-width: 1400px;
  margin: auto auto 10px auto;
  font-size: 40px;
  font-weight: 900;
  padding-top: 50px;
}

.subline-one{
  color: rgb(0, 0, 0);
  width: 95%;
  max-width: 1400px;
  margin: auto auto 10px auto;
  font-size: 30px;
  font-weight: 600;
}

.subline-just-in-one{
  color: rgb(255, 255, 255);
  width: 95%;
  max-width: 1400px;
  margin: auto auto 10px auto;
  font-size: 30px;
  font-weight: 600;
}

.subline-two {
  color: rgb(255, 255, 255);
  width: 95%;
  max-width: 1400px;
  margin: auto auto 10px auto;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
}

.subline-three{
  color: rgb(255, 255, 255);
  width: 95%;
  max-width: 1400px;
  margin: auto auto 10px auto;
  font-size: 30px;
  font-weight: 600;

}

.subline-one-one{
  color: rgb(0, 0, 0);
  width: 95%;
  max-width: 1400px;
  margin: auto auto 10px auto;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 50px;
}


.subline-two-one, .subline-three-one{
  color: rgb(255, 255, 255);
  width: 95%;
  max-width: 1400px;
  margin: auto auto 10px auto;
  font-size: 20px;
  font-weight: 500;
}


.guru-button-one{
  margin: 20px auto;
  padding: 10px 40px;
  font-weight: 900;
  font-size: 14px;
  color: rgb(255, 255, 255);
  border: none;
  background-color: rgb(255, 21, 21);
  border-radius: 10px;
  cursor: pointer;
  transition: ease-in-out 0.1s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.button-text{
  color: rgb(255, 21, 21);
  font-size: 16px;
  font-weight: 900;
}

.guru-button-one:hover{
  background-color: rgba(255, 21, 21, 0.8);
  transition: ease-in-out 0.1s;
}

.section-two{
  width: 100%;
  background-color: rgb(90, 11, 11);
  padding: 20px 0px;
}

.headline-two{
  color: rgb(255, 21, 21);
  width: 95%;
  max-width: 1400px;
  margin: auto auto 10px auto;
  font-size: 40px;
  font-weight: 900;
  text-align: center;
}

.guru-button-two{
  margin: 20px auto;
  padding: 10px 40px;
  font-weight: 900;
  font-size: 14px;
  color: rgb(255, 21, 21);
  border: none;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  cursor: pointer;
  transition: ease-in-out 0.1s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}


.guru-button-three-text{
  color: rgb(255, 123, 0);
  font-size: 16px;
  font-weight: 900;
  width: 95%;
  max-width: 1400px;
  margin: auto;
  transition: ease-in-out 0.2s;
}

.guru-button-three-text:hover{
  opacity: 0.6;
  transition: ease-in-out 0.2s;
}

.subline-two-one{
  color: rgb(255, 255, 255);
  width: 95%;
  max-width: 1400px;
  margin: auto auto 10px auto;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}


.guru-button-two:hover{
  background-color: rgba(255, 255, 255, 0.8);
  transition: ease-in-out 0.2s;
}

.section-three{
  width: 100%;
  max-height: 1000px;
  background-color: rgb(0, 0, 0);
  padding: 20px 0px;
}

.grand-portfolio-logos-container{
  display: flex;
  width: 95%;
  max-width: 1400px;
  margin: auto;
  justify-content: left;
  gap: 15px;
  padding: 20px 5px;
  overflow-x: auto;
  transition: 0.2s ease-in-out;
  
}


.logo-glass-container {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
  min-width: 90px;
  min-height: 90px;
  padding: 10px;
  border-radius: 50px; /* Runde Form */
  display: flex; /* Flexbox aktivieren */
  justify-content: center; /* Horizontal zentrieren */
  align-items: center; /* Vertikal zentrieren */
  border: solid 1px rgba(255, 255, 255, 0.4); /* Leichter, glasiger Rahmen */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
              inset 0 1px 3px rgba(255, 255, 255, 0.2); /* Schatten innen und außen */
  backdrop-filter: blur(10px); /* Unschärfe für Glaseffekt */
  -webkit-backdrop-filter: blur(10px); /* Für Safari */
  transition: all 0.3s ease-in-out; /* Sanfte Übergänge */
}


.grand-portfolio-logo {
  max-height: 30px;
  max-width: 65px;
}

.logo-glass-container:hover {
  scale: 1.05;
}


.headline-three{
  color: rgb(255, 123, 0);
  width: 95%;
  max-width: 1400px;
  margin: 20px auto 10px auto;
  font-size: 40px;
  font-weight: 900;
}

.landingpage-iphone{
  width: 100%;
  padding:100px 0px;
  object-fit: cover;
  background-position: 50% 50%; /* Standard: Bildmitte */
  background-color: rgb(0, 0, 0);
  background-image: url("https://www.gowiththegurus.de/images/landingpage/GoWithTheGurus_Header.webp");
  background-position: 0% 40%; /* Standard: Bildmitte */
  background-repeat: no-repeat;
  display: grid;
  place-items: center;

}

.landingpage-iphone-text-container{
  width: 95%;
  max-width: 500px;
  font-size: 16px;
  padding: 80px 10px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: rgba(255, 255, 255, 0.9);
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.landingpage-iphone-2{
  height: 44vh;
  background-size: 50%;
  object-fit: cover;
  background-image: url("https://www.gowiththegurus.de/images/landingpage/GoWithTheGurus_LandingPage_iPhone_Front2.webp");
  background-position: 50% 4%; /* Standard: Bildmitte */
  background-repeat: no-repeat;
  border-bottom: 2px solid rgb(255, 255, 255);
  width: 95%;
  max-width: 1400px;
  margin: auto;
  margin-bottom: 20px;
}

.just-in-section{
  padding-bottom: 50px;
  background-color: rgb(0, 0, 0);
}



@media (max-width: 1000px) {
  .landing-page-first-row-container{
    flex-direction: column;
}


.landingpage-iphone-2{
  height: 20vh;
}
}

.wishlist-container{
  width: 95%;
  max-width: 1400px;
  margin: auto;
}

.wishlist-headline{
  margin-top: 50px;
  color: white;
  font-size: 20px;
  font-weight: 900;
}

.wishlist-headline-2{
  margin-top: 10px;
  color: white;
  font-size: 16px;
  font-weight: 500;
}


.wishlist-input {
  width: 50%;
  max-width: 400px;
  padding: 12px 15px;
  margin-top: 10px;
  font-size: 16px;
  border: 2px solid rgb(255, 123, 0);
  background-color: rgba(0, 0, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s ease;
  caret-color: rgb(255, 255, 255);
  margin-right: 10px;
}

.wishlist-button {
  padding: 10px 0px 50px 0px;
  background-color: rgba(177, 86, 0, 0);
  color: rgb(255, 123, 0);
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.wishlist-button:hover{
  opacity: 0.8;
}

.wishlist-input::placeholder {
  color: rgba(255, 255, 255, 0.397);
}

.blog-title-text{
  color: rgb(50, 240, 170);
  margin-bottom:"5px";
  transition: ease-in-out 0.2s;
}

.blog-title-text:hover{
  opacity: 0.5;
  transition: ease-in-out 0.2s;
}

.blog-date-text{
  color: rgba(255, 255, 255, 0.8);
  font-weight: 100
}
