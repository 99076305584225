.stock-page-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .stock-page-container h2 {
    margin-bottom: 20px;
    color: #333;
  }

  .stock-page-header-container {
    width: 100%;
    padding:150px 0px;
    object-fit: cover;
    background-size:100%;
    background-position:50% 50%; /* Standard: Bildmitte */
    background-color: rgb(0, 34, 18);
    background-image: url("https://www.gowiththegurus.de/images/header/header_wallpaper.webp");
    background-repeat: no-repeat;
  }

  .stock-page-searchbar{
    width: 50%;
    padding-top: 50px;
    margin: auto;
  }

  .stock-page-header{
    font-size: 24px;
    font-weight: 900;
    width: 95%;
    max-width: 1400px;
    margin: auto;
    padding: 20px 0;
    align-items: end;
  }



  @media (max-width: 1000px) {
    .stock-page-header-container {
      background-size:200%;
    }
  }