.cashflow-chart-background{
    background-color: rgb(0, 68, 53);
    padding-bottom: 100px;
}

.cashflow-chart{
    margin: 5px auto 0px auto;
    width: 95%;
    max-width: 1400px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}