.stock-chart-container {
  width: 95%;
  max-width: 1400px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0px auto 50px auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);

}

.stock-chart-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.stock-chart-container p {
  margin-top: 20px;
  color: #555;
}

.stock-chart-search-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

