.impressum-container{
    width: 95%;
    max-width: 1400px;
    margin: auto;
    padding-bottom: 30px;
}

.impressum-iphone{
    height: 60vw;
    background-size: 100%;
    object-fit: cover;
    background-color: rgb(0, 34, 18);
    background-image: url("https://www.gowiththegurus.de/images/landingpage/GoWithTheGurus_Banner.webp");
    background-position: 50% 65%; /* Standard: Bildmitte */
    background-repeat: no-repeat;
}
