/* Allgemeine Stile */
.newsletter-background {
  width: 100%;
  padding: 50px 20px;
  background-color: #1f2937; /* Modernes Dunkelgrau */
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter-container {
  width: 95%;
  max-width: 1400px;
  background: #ffffff00; /* Weißer Hintergrund für Kontrast */
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0); /* Weicher Schatten */
  padding: 0px 20px;
  text-align: center;
}

.newsletter-headline {
  color: #ffffff; /* Dunkelgrau für einen modernen Look */
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;

}

.newsletter-subheadline {
  color: #ffffffbb; /* Helleres Grau für weniger Aufmerksamkeit als die Hauptheadline */
  font-size: 16px; /* Kleinere Schriftgröße */
  font-weight: normal;
  margin-bottom: 20px;
  line-height: 1.6; /* Angenehmer Zeilenabstand */

}

/* Formular-Stile */
.newsletter-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.newsletter-input {
  width: 100%;
  max-width: 400px;
  padding: 12px 15px;
  font-size: 16px;
  border: 2px solid #e5e7eb38; /* Hellgrau */
  background-color: #1f2937;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
  caret-color: white;
  color: white;
}

.newsletter-input:focus {
  border-color: #ffffff8f; /* Blaue Umrandung bei Fokus */
}

.newsletter-button {
  padding: 12px 20px;
  background-color: #1f2937; /* Helles Blau */
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.newsletter-button:hover {
  opacity: 0.9; /* Etwas dunkleres Blau beim Hover */
  transition: 0.2s ease-in-out;
}

.newsletter-button:active {
  transform: scale(0.98); /* Subtiler Klick-Effekt */
}
