.guru-table {
    margin: 50px auto;
    width: 95%;
    max-width: 1400px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}



.big-portfolio-table {
    margin: 00px auto 100px auto;
    width: 95%;
    overflow: auto;
    max-width: 1400px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}



.big-portfolio-info-container{
    width: 95%;
    max-width: 1400px;
    display: flex;
    margin: 20px auto 20px auto;
    gap: 20px;
}

.big-portfolio-info-spot-container{
    background-color: #010808e7;
    min-width: 200px;
    border-radius: 10px;
    color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    padding: 0px 20px;
}

/* Portfolio Summary */
.portfolio-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
    font-size: 1em;
    font-weight: 500;
}


/* Table Styling */
table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 10px;
    table-layout: fixed; /* Feste Spaltenbreiten */
}

th, td {
    padding: 12px;
    height: 30px;
    text-align: left;
    font-weight: 700;
    font-size: 0.95em;
}

td {
    color: #444;
}



th {
    background-color: #010808e7;
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    height: 50px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}


.big-portfolio-table th:first-child {
    border-radius: 10px 0px 0px 0px;
    width: 50px;
    cursor:default;
}

tr {
    height: 70px;
}

tr:nth-child(even) {
    background-color: #f4f8fb;
}

tr:hover {
    background-color: #dbe9f7;
    transition: background-color 0.3s ease;
}



.button-container{
    display: flex;
    justify-content: space-between;
}



/* Pagination Controls */
.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-controls button {
    margin: 0 5px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: #010808e7;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
}

.pagination-controls button:hover {
    opacity: 0.9;
    transition: ease-in-out 0.1s;
}

.pagination-controls button:disabled {
    background: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.pagination-controls button:disabled:hover {
    transform: none;
}

.rows-per-page label {
    margin-right: 10px;
    font-weight: 600;
    color: #555;
}



/* Responsive Design */
@media (max-width: 1000px) {

    .desktop-only {
        display: none;
      }
    
      .mobile-only {
        display: table-row;
      }

      .expanded-content {
        font-size: 14px;
        color: #444;
        text-align: left;
      }

    
      .expanded-row {
        display: none;
      }

    /* Table Styling */
table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 10px;
    table-layout: fixed;

}



    .content{
        padding: 0px;
    }
    
    .guru-table {
        overflow: auto;

    }

    .big-portfolio-table {
        width: 95%;
        overflow: auto;
        padding: 0px;
    }

    .expanded-row {
        display: table-row;
      }

    th, td {
        padding: 10px;
    }

    .big-portfolio-info-container{
        display: flex;
        gap:20px;
        padding: 0px 0px 10px 0px;
        margin: 20px auto 10px auto;
        justify-content: space-between;
        overflow: auto;
    }

    .big-portfolio-info-spot-container{
        min-width: 45vw;
        max-width: 200px;
        padding: 20px 10px;
    }
    

    .big-portfolio-table th:nth-child(3), 
    .big-portfolio-table td:nth-child(3), 
    .big-portfolio-table th:nth-child(5), 
    .big-portfolio-table td:nth-child(5),
    .big-portfolio-table th:nth-child(6), 
    .big-portfolio-table td:nth-child(6), 
    .big-portfolio-table th:nth-child(7), 
    .big-portfolio-table td:nth-child(7),
    .big-portfolio-table th:nth-child(8), 
    .big-portfolio-table td:nth-child(8)
    {
        display: none;
    }


    .big-portfolio-table th:nth-child(7), 
    .big-portfolio-table td:nth-child(7)
    {
        width: 110px;
    }

    .big-portfolio-table th:nth-child(4), 
    .big-portfolio-table td:nth-child(4)
    {
        text-align: right;
    }

    td:first-child, th:first-child{
        width: 60px;
    }



    .guru-table th:nth-child(3), 
    .guru-table td:nth-child(3), 
    .guru-table th:nth-child(4), 
    .guru-table td:nth-child(4), 
    .guru-table th:nth-child(5), 
    .guru-table td:nth-child(5), 
    .guru-table th:nth-child(6), 
    .guru-table td:nth-child(6)
    {
        display: none;
    }

    .guru-table th:nth-child(1), 
    .guru-table td:nth-child(1) 
    {
        width: 100px;
    }

    .guru-table th:nth-child(2), 
    .guru-table td:nth-child(2) 
    {
        width: 160px;
        text-align: right;
    }

    .guru-table th:nth-child(3), 
    .guru-table td:nth-child(3) 
    {
        text-align: right;
    }



    .portfolio-summary {
        flex-direction: column; /* Optional, da "row" der Standardwert ist */
        font-size: 0.9em;
        align-items: flex-start;
    }

    .rows-per-page {
        text-align: center;
        margin-top: 15px;
    }

    .pagination-controls {
        flex-wrap: wrap;
        margin-top: 15px;
    }

    .pagination-controls button {
        margin: 5px;
        padding: 10px;
        font-size: 12px;
    }
}

.sort-arrow-down {
    vertical-align: middle; /* Alternativ: baseline, top, bottom */
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
  }

  .sort-arrow-up {
    transform: rotateX(180deg);
    vertical-align: middle; /* Alternativ: baseline, top, bottom */
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
  }

  .sort-arrow-right {
    transform: rotate(270deg);
    vertical-align: middle; /* Alternativ: baseline, top, bottom */
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
  }