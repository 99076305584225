/* LastIn.css */
.news-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0px;
  width: 95%;
  max-width: 1400px;
  margin: auto;
  overflow-x: auto;

}



.news-container {
  display: flex;
  background-color: rgba(255, 255, 255, 0.423);
  border-radius: 15px;
  box-shadow: 0 40px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  flex-shrink: 0; /* Verhindert, dass die Boxen in der Größe schrumpfen */
  background-size: cover;
  background-position: center;
  scale: 0.99;
  transition: ease-in-out 0.2s;
  justify-content: space-between;
}

.news-container:hover{
  scale: 1;
  transition: ease-in-out 0.2s;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  height: 50px;
}

.image-section {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.fonds-name {
  color: rgba(255, 255, 255, 1);
  font-size: 40px;
  font-weight: bold;
  z-index: 1; /* Sicherstellen, dass der Text über dem Bild liegt */
}

.info-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(50px);
  min-width: 40%;
  border-radius: 0px 20px 20px 0px;
  box-shadow: 0 40px 8px rgba(0, 0, 0, 0.2);
}

.just-in-info-container{
  padding: 20px;
  text-align: right;
  color: white;
}

.info-section p {
  margin: 0;
  font-size: 14px;
  color: white;
}

.info-section strong {
  font-weight: bold;
}

.just-in-text{
  font-weight: 900;
  margin-top: 20px;
  color: white;
}


