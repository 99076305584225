.about-guru {
    width: 95%;
    max-width: 1400px;
    margin: 40px auto;
  }
  

  .about-guru-text-container{
    background-color: rgb(255, 255, 255);
    padding: 20px 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
.link-container{
    margin-top: 50px;
    font-weight: 900;
}

.link:hover{
    opacity: 0.8;
    transition: 0.2s ease-in-out;
}

.link{
    transition: 0.2s ease-in-out;
}