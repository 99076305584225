.blog-post-header-image{
    width: 100%;
    padding:100px 0px;
    object-fit: cover;
    background-size:100%;
    background-position: 50% 50%; /* Standard: Bildmitte */
    background-color: rgb(0, 34, 18);
    background-image: url("https://www.gowiththegurus.de/images/blog/Header_background.webp");
    background-repeat: no-repeat;
}

.blog-post-header-image-post-2{
    width: 100%;
    padding:100px 0px;
    object-fit: cover;
    background-size:150%;
    background-position:50% 50%; /* Standard: Bildmitte */
    background-color: rgb(0, 34, 18);
    background-image: url("https://www.gowiththegurus.de/images/blog/Header_background_2.webp");
    background-repeat: no-repeat;
}

.blog-post-header-image-post-3{
    width: 100%;
    padding:100px 0px;
    object-fit: cover;
    background-size:150%;
    background-position:50% 50%; /* Standard: Bildmitte */
    background-color: rgb(0, 34, 18);
    background-image: url("https://www.gowiththegurus.de/images/blog/Header_background_3.webp");
    background-repeat: no-repeat;
}

.post-container {
    width: 95%;
    max-width: 1400px;
    margin: 40px auto;
}

.post-container-header-background{
    background-color: black;
    color: white;
}

.post-container-header{
    width:95%;
    margin: auto;
    max-width: 1400px;
    padding: 20px 0px
}

.performance-table{
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.blog-post-header-container{
    width: 80%;
    max-width: 800px;
    padding: 100px 0px;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    backdrop-filter: blur(10px);
    color: rgba(255, 255, 255, 0.9);
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.post-title {
    width: 95%;
    max-width: 1400px;
    margin: 0px auto 0px auto;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    font-weight: 700;
    font-size:32px;
}

.post-title-sub {
    width: 100%;
    max-width: 1400px;
    margin: 10px auto;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    font-weight: 300;
}

.post-meta {
    width: 95%;
    max-width: 1400px;
    margin: 20px auto 0px auto;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
}

.index-list{
    color: rgb(255, 128, 0);
    font-size: 16px;
    font-weight: 700;
    transition: ease-in-out 0.2s;
}

.index-list:hover{
    opacity: 0.6;
    transition: ease-in-out 0.2s;
}

.post-content {
    font-size: 18px;
    color: #333;
    line-height: 1.8;
}

.post-section {
    margin-bottom: 30px;
}

.post-content ul {
    margin: 20px 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.post-content li {
    display: flex;
    align-items: center;
    background: #f8f9fa;
    padding: 18px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    gap: 18px;
    font-weight: 500;
    transition: transform 0.2s ease-in-out;
}

.post-content li:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.08);
}

.sold {
    color: #d9534f;
    font-weight: bold;
}

.holding-logo-blog {
    width: 60px;
    height: 60px;
    object-fit: contain;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    flex-shrink: 0;
}

@media (max-width: 768px) {
    .post-title {
        font-size: 28px;
    }
    .post-title-sub {
        font-size: 16px;
    }
    .post-content {
        font-size: 16px;
    }
    .post-content ul {
        gap: 10px;
    }
    .post-content li {
        padding: 14px;
    }

    .blog-post-header-image{
        background-size:200%;
    }

    .blog-post-header-image-post-2{
        background-size:400%;
    }

    .blog-post-header-image-post-3{
        background-size:400%;
    }

}