.guru-holdings-container{
    background-color: rgb(100, 150, 250);
    padding-bottom: 50px;
}

.guru-holdings-table{
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.quarter-section{
    width: 95%;
    max-width: 1400px;
    margin: auto;
}

@media (max-width: 1000px) {
    .guru-holdings-table th:nth-child(3), 
    .guru-holdings-table td:nth-child(3),
    .guru-holdings-table th:nth-child(4), 
    .guru-holdings-table td:nth-child(4)
    {
        display: none;
    }

    .guru-holdings-table th:nth-child(2), 
    .guru-holdings-table td:nth-child(2)
    {
        text-align: right;
    }

    .guru-holdings-table th:nth-child(1), 
    .guru-holdings-table td:nth-child(1)
    {
        width: 200px;
    }

}