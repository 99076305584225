.header {
  background-color: #000000e7;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.header-content {
  max-width: 1400px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 60px;
}

.search-container {
  display: flex;
  position: relative;
}

.main-search {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff65;
  border: none;
}

.search-icon {
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.search-icon :hover {
  opacity: 0.6;
  transition: ease-in-out 0.2s;
}

/* Pop-Up Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative; /* Macht es zum Bezugspunkt für absolute Kinder */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 95%;
  max-width: 500px;
  gap: 20px;
  font-weight: 900;
  height: 150px; /* Maximale Höhe, damit es scrollbar ist */
  overflow: hidden; /* Verhindert Überlaufen */
}

.popup-search {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  height: 30px;
  padding: 20px;
  border-radius: 20px;
  background-color: #010808e7;
  font-family: 'Roboto', Arial, sans-serif;
  border: none;
}

.popup-search:focus {
  outline: none;
}

.close-popup {
  margin-top: 10px;
  padding: 5px 10px;
  background: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


/* Burger menu toggle button */
.menu-toggle {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #ff5e00e7;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  flex-direction: column; /* Streifen werden übereinander gestapelt */
  justify-content: center; /* Zentriert die Streifen vertikal */
  align-items: center; /* Zentriert die Streifen horizontal */
  gap:5px;
  z-index: 1001;
}

/* Streifen im Burger-Menü */
.menu-toggle span {
  display: block;
  width: 30px;
  height: 4px;
  background-color: white;
  border-radius: 2px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: relative;
}

/* Erster Streifen */
.menu-toggle span:first-child {
  transform-origin: top left;
}

/* Zweiter Streifen */
.menu-toggle span:last-child {
  transform-origin: bottom left;
}

/* Zustand "X" (Menü geöffnet) */
.menu-toggle.open span:first-child {
  transform: rotate(45deg) translate(0px, -8px);
}

.menu-toggle.open span:last-child {
  transform: rotate(-45deg) translate(0px, 8px);
}

/* Animation zurück zum Burger-Menü */
.menu-toggle:not(.open) span:first-child {
  transform: rotate(0) translate(0, 0);
}

.menu-toggle:not(.open) span:last-child {
  transform: rotate(0) translate(0, 0);
}

/* Ausblenden */
.menu-toggle.hidden {
  opacity: 0;
  transform: translateY(20px); /* Leicht nach unten verschieben */
  pointer-events: none; /* Nicht klickbar, während es versteckt ist */
}

/* Einblenden */
.menu-toggle.visible {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  transition: ease-in-out 0.2s;
}

/* Zusätzliche Animation beim Scrollen */
.menu-toggle.scrolling {
  transition: opacity 0.15s ease-out, transform 0.15s ease-out;
}

/* Popup Navigation */
.menu-popup {
  position: fixed;
  bottom: 100px;
  font-size: 20px;
  right: 30px;
  text-align: right;
  background-color: #ff5e00e7;
  border-radius: 10px;
  padding: 30px 10px 30px 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 999; /* Popup unter dem Button */
  opacity: 1; /* Standardmäßig sichtbar */
  transform: translateY(0); /* Standardposition */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Sanfte Übergänge */
  z-index: 1001;
}

.menu-overlay {
display: none;
}


/* Popup Ausblenden */
.menu-popup.hidden {
  opacity: 0;
  transform: translateY(10px); /* Nach unten verschieben */
  pointer-events: none; /* Nicht klickbar */
  transition: opacity 0.2s ease-in-out;
}

/* Popup Einblenden */
.menu-popup.visible {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto; /* Klickbar */
  transition: opacity 0.2s ease-in-out;
}

.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-item a {
  color: white;
  text-decoration: none;
  font-size: 1em;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-item a:hover {
  color: #ffffffd3;
}

/* Container für die Vorschläge */
.suggestions-list {
  position: relative;
  background-color: #ffffff; /* Hintergrundfarbe */
  border: 1px solid #ccc; /* Rahmen */
  border-radius: 5px; /* Abgerundete Ecken */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Schatten */
  z-index: 999; /* Über dem Input-Feld */
  padding: 0;
  margin-top: 10px;
  list-style: none; /* Entfernt Standard-Listenstil */
  width: 95%;
  max-width: 500px;
}


.menu-list {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
  list-style: none; /* Entfernt die Punkte in der Liste */
}

@media (min-width: 600px) {
  .menu-toggle, .menu-popup{
    display: none;
  }

}

@media (max-width: 600px) {
  .menu-list{
    display: none;
  }

  .menu-overlay {
    display: inline;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
  }

}
