 * {
    font-family: 'Roboto', Arial, sans-serif;
 }

.tab-container {
    display: flex;
    border-bottom: 0px solid #8400ff;
    margin-bottom: 20px;
    justify-content: center;
    height:40px;

  }
  
  .tab-button {
    background: none;
    opacity: 0.8;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 900;
    transition: ease-out 0.2s;
    -webkit-appearance: none; /* Entfernt Standardstile auf iOS */
    -moz-appearance: none;    /* Entfernt Standardstile auf Firefox */
    appearance: none;         /* Entfernt Standardstile für moderne Browser */
    color:#001638e7
}


  .tab-button:hover {
    opacity: 0.6;
    transition: ease-in-out 0.2s;
  }

  
  .tab-button.active {
    opacity: 1;
    border-bottom: 2px solid #010808e7;
    color: #010808e7;
    font-weight: 900;
    transition: ease-out 0.2s;
    outline: none; /* Entfernt den Fokus-Rahmen */
    background: none; /* Entfernt mögliche Hintergrundfarbe */
    color: inherit; /* Verwendet die Farbe aus deinem Stil */
  }
  

  .tab-button:active {
    outline: none; /* Entfernt den Fokus-Rahmen */
    background: none; /* Entfernt mögliche Hintergrundfarbe */
    color: inherit; /* Verwendet die Farbe aus deinem Stil */
}

.portfolio-chart-container{
  background-color: #001638e7;
  padding: 50px 0px;
}

.headline{
  color: white;
  width: 95%;
  max-width: 1400px;
  margin: 50px auto 10px auto;
  font-size: 30px;
  font-weight: 900;
}

.gics-line{
  width: 95%;
  max-width: 1400px;
  margin: auto;
  margin-bottom: 20px;
  color: white;
}

.disclaimer-header{
  width: 95%;
  max-width: 1400px;
  font-size: 14px;
  font-weight: 900;
  margin: 10px auto 5px;
  color: white;
}

.disclaimer-line{
  width: 95%;
  max-width: 1400px;
  font-size: 12px;
  margin: 0px auto 50px ;
  color: white;
}