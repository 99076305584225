.kofi-widget-container {
    display: flex;
    width: 95%;
    max-width: 1400px;
    margin: auto;
  }
  
  .kofi-widget {
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, #ff8e3c 0%, #ff7425 100%);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    text-align: center;
    color: white;
    transition: ease-in-out 0.2s;
  }
  
  .kofi-icon img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    animation: pulse-shake 1.5s infinite ease-in-out;
}

@keyframes pulse-shake {
  0% {
    transform: scale(1) rotate(0deg);
  }
  20% {
    transform: scale(1.05) rotate(-3deg);
  }
  40% {
    transform: scale(0.95) rotate(3deg);
  }
  60% {
    transform: scale(1.05) rotate(-3deg);
  }
  80% {
    transform: scale(0.95) rotate(3deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
  
  .kofi-content h3 {
    margin: 0;
    font-size: 18px;
    text-align: left;
  }
  
  .kofi-content p {
    margin: 5px 0;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  }
  
  .kofi-content small {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    text-align: left;
  }
  
  .kofi-button {
    color: #ff813f;
    border: none;
    transition: 0.1s ease-in-out;
    cursor: pointer;
  }
  
  .kofi-widget:hover {
    color: white;
    box-shadow: 0 0px 10px rgba(255, 188, 112, 0.3);
    transition: 0.2s ease-in-out;
    transform: scale(1.005);
  }
  
  .kofi-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(20px);
    z-index: 9999;
    overflow-y: auto; /* Ermöglicht Scrollen auf mobilen Geräten */
  }

  .kofi-popup-content {
    position: relative;
    background: rgba(255, 255, 255, 0);
    border-radius: 10px;
    max-width: 600px;
    width: 90%; /* Breite für Mobilgeräte angepasst */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out; /* Sanftes Einblenden */
  }

  /* Popup Header mit X-Button */
.kofi-popup-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 15px 15px 0px 0px;
    background-color: #ff813f;
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  
  
  #kofiFrameContainer {
    width: 100%;
  }

  .kofi-popup-content-header{
    display: flex;
    justify-content: space-around;
    padding: 20px 0px;
    border-radius: 15px 15px 0px 0px;
    background-color: #ff813f;

  }

  .kofi-popup-content-header-addon {
    width: 100%;
    height: 50px;
    background-color: #ffffff;
  }

  .kofi-popup-close {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    color: white;
    background: none;
    border: none;
    padding: 0;
  }
  
  /* Media Query für Mobilgeräte */
@media (max-width: 1000px) {
    .kofi-popup-close {
      display: block; /* X-Button wird nur auf Mobilgeräten sichtbar */
    }

    .kofi-popup-content {
        max-height:
        600px;
      }

      .kofi-widget-container{
        justify-content: center;
      }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }