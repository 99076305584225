.sector-overview{
  width: 95%;
  max-width: 1400px;
  margin: auto;
  gap: 20px;
  display: flex;
  justify-content: space-between;
}

.table-chart-container{
  background-color: white;
  width: 100%;
  border-radius: 11px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.chart-container {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-top: 10px 50px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}


.sector-table th:first-child, 
.sector-table td:first-child
{
    width: 60px;
}

.chart-main-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


@media (max-width: 1000px) {
  .sector-overview {
    flex-direction: column; /* Inhalte untereinander stapeln */
  }

  .sector-table th:nth-child(3), 
  .sector-table td:nth-child(3)
  {
      display: none;
  }

  .sector-table th:last-child, 
  .sector-table td:last-child
  {
      text-align: right;
  }


}

.details-container {
  width: 95%;
}

.stock-details {
  display: flex;
  flex-direction: column;
}

.stock-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.stock-info {
  display: flex;
  align-items: center;
}

.stock-info img {
  max-width: 100px;
  max-height: 30px;
  margin-right: 10px;
}

.stock-value {
  text-align: right;
  font-family: 'Courier New', Courier, monospace; /* Optional: Monospaced font for better alignment */
}

.stock-item span {
  margin-right: 20px;
}