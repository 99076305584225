

.search-input {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ffffff51;
  border-radius: 10px;
  box-sizing: border-box;
  width: 30%;
  margin: auto;
  min-width: 250px;
  color: white;
  caret-color: white;
  background-color: rgba(0, 0, 0, 0);
}

/* Rahmen dünner machen, wenn das Eingabefeld fokussiert ist */
.search-input:focus {
  border-width: 1px;
  border-color: #ffffff;
  outline: none; /* Entfernt den Standard-Fokusrahmen */
}

/* Farbe des Platzhaltertextes anpassen */
.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5); /* Beispiel: halbtransparentes Weiß */
}

.ticker-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 9999;
  max-height: 200px;
  overflow-y: auto;
  width: 30%;
  box-sizing: border-box;
  list-style: none; /* Entferne die Listenpunkte */
  padding: 0; /* Entferne das Standard-Padding */
  margin: auto; /* Entferne den Standard-Rand */
  min-width: 250px;
}

.suggestion-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0; /* Ändere die Hintergrundfarbe beim Hover */
}

.ticker-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}