

.main-container {
  display: flex;
  flex-direction: column;
  min-height:1000px;
}

a{
  text-decoration: none;
  color: #444
}




.loading, .error, .no-data {
  text-align: center;
  font-size: 1em;
  color: #444;
  margin: 20px 0;
}

.error {
  color: red;
}

/* Top Holding */
.top-holding {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.holding-details {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
}


.holding-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
}



.holding-name {
  font-weight: 900;
}

.holding-percentage {
  text-align: left;
  font-size: 0.85em;
  color: #777;
  font-weight: 900;

}


.lamp-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.lamp {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
      transform: scale(0.8);
      opacity: 0.8;
  }

  50% {
      transform: scale(1);

  }

  100% {
      transform: scale(0.8);
      opacity: 0.8;
  }
}

.red-bg {
  background-color: rgb(231, 94, 94);
}

.red-c {
  color: rgb(231, 94, 94);
}

.orange-bg {
  background-color: rgb(245, 150, 8);
}

.orange-c {
  color: rgb(245, 150, 8);
}

.green-bg {
  background-color: rgba(67, 185, 82);
}

.green-c {
  color: rgba(67, 185, 82);
}