.guru-current-table {
    margin: 5px auto 50px auto;
    width: 95%;
    max-width: 1400px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.guru-current-table th:first-child {
    width: 60px;
}

/* Entferne das Standard-Styling */
input[type="range"] {
  appearance: none;
  width: 100%;
  background: transparent; /* transparent für Webkit-Browser */
}

/* Fokuseffekt entfernen */
input[type="range"]:focus {
  outline: none;
}

/* Gestalte die Spur (Track) in Chrome, Safari und Edge */
input[type="range"]::-webkit-slider-runnable-track {
  height: 10px;
  background: #ddd;
  border-radius: 5px;
  cursor: pointer;
}

/* Gestalte den Schieberegler (Thumb) in Chrome, Safari und Edge */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background: #000;
  border-radius: 50%;
  margin-top: -5px; /* Zentriert den Thumb vertikal auf der Spur */
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Hover- und Focus-Effekte für den Thumb */
input[type="range"]::-webkit-slider-thumb:hover,
input[type="range"]:focus::-webkit-slider-thumb {
  background: #444;
}

/* Für Firefox: Spur (Track) */
input[type="range"]::-moz-range-track {
  height: 10px;
  background: #ddd;
  border-radius: 5px;
  cursor: pointer;
}

/* Für Firefox: Schieberegler (Thumb) */
input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Hover-Effekt in Firefox */
input[type="range"]::-moz-range-thumb:hover {
  background: #444;
}

/* Für Internet Explorer */
input[type="range"]::-ms-track {
  height: 10px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #ddd;
  border-radius: 5px;
}

input[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 5px;
}

input[type="range"]::-ms-thumb {
  height: 20px;
  width: 20px;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

input[type="range"]:focus::-ms-thumb {
  background: #444;
}



.year-navigation{
  width: 95%;
  height: 100%;
  max-width: 1400px;
  margin: auto;
  display: flex;
  gap: 10px;
}


/* Responsive Design */
@media (max-width: 1000px) {


.guru-current-table th:nth-child(3), 
.guru-current-table td:nth-child(3), 
.guru-current-table th:nth-child(5), 
.guru-current-table td:nth-child(5), 
.guru-current-table th:nth-child(6), 
.guru-current-table td:nth-child(6), 
.guru-current-table th:nth-child(7), 
.guru-current-table td:nth-child(7),
.guru-current-table th:nth-child(8), 
.guru-current-table td:nth-child(8),
.guru-current-table th:nth-child(9), 
.guru-current-table td:nth-child(9)
{
    display: none;
}

.guru-current-table th:nth-child(4),
.guru-current-table td:nth-child(4)
 {
  text-align: right;
}


}


.expand-controls {
  display: flex;
  justify-content: right;
  margin: 20px;
}

.expand-controls button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  background-color: #010808e7;
  padding: 15px;
  border-radius: 100px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}


.plus-icon {
  width: 15px;
  height: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

}

.plus-icon::before,
.plus-icon::after {
  content: '';
  position: absolute;
  background-color: #ffffffe7;
  border-radius: 10px;
  width: 20px;
  height: 3px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  
}

.plus-icon::before {
  transform: rotate(0deg); /* Horizontale Linie */
}

.plus-icon::after {
  transform: rotate(270deg); /* Vertikale Linie */
}

button.expanded .plus-icon::after {
  transform: rotate(0deg); /* Vertikale Linie verschwindet => wird waagerecht */
}

button.expanded .plus-icon::before {
  transform: rotate(0deg); /* Bleibt waagerecht */
}



.year-navigation span {
  display: flex;
  align-items: flex-end; /* Text am unteren Rand */
  justify-content: center;
  font-size: 16px;
}

.portfolioButton {
  background-color: #010808e7;
  height: 40px; /* Höhe hinzufügen, um einen perfekten Kreis zu gewährleisten */
  width: 40px; /* Höhe hinzufügen, um einen perfekten Kreis zu gewährleisten */
  padding: 10px; /* Kein Padding, damit das Bild zentriert bleibt */
  border-radius: 50%; /* Macht das Element kreisförmig */
  align-items: center; /* Vertikale Zentrierung */
  justify-content: center; /* Horizontale Zentrierung */
  border: none; /* Optional: Entferne den Button-Rand */
  cursor: pointer; /* Zeigt, dass es ein klickbares Element ist */
  gap: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: ease-in-out 0.2s;
}

.portfolioButton img {
  height: 100%; /* Höhe passend zum Kreis */
  transform: rotate(0deg); /* Rotiert das Bild */
}

.portfolioButton:hover{
  opacity: 0.9;
  transition: ease-in-out 0.2s;
}

