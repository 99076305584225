/* Allgemeines Styling */
.faq-page {
    max-width: 1400px;
    width: 95%;
    margin: 0 auto 50px auto;
    color: #333;
}

/* Header */
.faq-header {
    text-align: left;
    margin-bottom: 40px;
}

.faq-header h1 {
    font-size: 30px;
    color: #000000; /* Rot für Akzente */
    margin-bottom: 10px;
}

.faq-header p {
    font-size: 1.2rem;
    color: #555;
}

/* Akkordeon Container */
.accordion {
    display: flex;
    flex-direction: column;
}

/* Akkordeon Item */
.accordion-item {
    border-radius: 8px;
    overflow: hidden;
}

/* Frage (Header) */
.accordion-question {
    background-color: #ff000000;
    padding: 20px 0px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    transition: background-color 0.2s ease;
}


/* Pfeil (Icon) */
.accordion-arrow {
    width: 20px;
    height: 20px;
    transition: transform 0.4s ease;
}

.accordion-arrow.open {
    transform: rotate(180deg); /* Dreht den Pfeil */
}

/* Antwort (Content) */
.accordion-answer {
    background-color: #f9f9f900;
    color: #555;
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
}
