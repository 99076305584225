.buys-and-sells-button {
  width: 95%;
  height: 100%;
  max-width: 1400px;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

/* Radio-Buttons */
input[type="radio"] {
  appearance: none; /* Entfernt den Standard-Radio-Button */
  -webkit-appearance: none; /* Entfernt den Standard-Radio-Button (Safari) */
  width: 20px; /* Größe des Buttons */
  height: 20px;
  border: 2px solid #bbb; /* Schwarzer Rahmen */
  border-radius: 50%; /* Runde Form */
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: #bbb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Hover-Effekt */
input[type="radio"]:hover {
  background-color: #999; /* Rahmen wird dunkler */
  transition: 0.2 ease-in-out;
}

/* Checked-State mit Haken */
input[type="radio"]:checked {
  background-color: #010808e7; /* Schwarzer Hintergrund */
  border-color: #010808e7; /* Rahmen passt sich an */
}

input[type="radio"]:checked::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Label und Radio als Flexbox */
label {
  display: flex;
  align-items: center; /* Vertikale Zentrierung */
  gap: 5px; /* Abstand zwischen Radio und Text */
  font-weight: bold;
  font-size: 16px;
  color: #333;
  cursor: pointer; /* Klickbar auch auf Text */
  transition: all 0.3s ease; /* Sanfter Übergang */
  
}

/* Hover-Effekt für Label */
label:hover {
  color: #000; /* Text wird dunkler */
}

/* Fokus-Effekt für Tastatursteuerung */
input[type="radio"]:focus-visible {
  outline: 2px solid #000;
  outline-offset: 2px;
}
